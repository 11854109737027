module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Miró","short_name":"Matrícula 2021","start_url":"/","background_color":"#fff","theme_color":"#933B60","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cd0f49767f9cf690c4403837cb2c52d2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NVR96DR"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
